const p = function polyfill(modulePath = '.', importFunctionName = '__import__') {
    try {
        self[importFunctionName] = new Function('u', `return import(u)`);
    }
    catch (error) {
        const baseURL = new URL(modulePath, location);
        const cleanup = (script) => {
            URL.revokeObjectURL(script.src);
            script.remove();
        };
        self[importFunctionName] = (url) => new Promise((resolve, reject) => {
            const absURL = new URL(url, baseURL);
            // If the module has already been imported, resolve immediately.
            if (self[importFunctionName].moduleMap[absURL]) {
                return resolve(self[importFunctionName].moduleMap[absURL]);
            }
            const moduleBlob = new Blob([
                `import * as m from '${absURL}';`,
                `${importFunctionName}.moduleMap['${absURL}']=m;`
            ], { type: 'text/javascript' });
            const script = Object.assign(document.createElement('script'), {
                type: 'module',
                src: URL.createObjectURL(moduleBlob),
                onerror() {
                    reject(new Error(`Failed to import: ${url}`));
                    cleanup(script);
                },
                onload() {
                    resolve(self[importFunctionName].moduleMap[absURL]);
                    cleanup(script);
                }
            });
            document.head.appendChild(script);
        });
        self[importFunctionName].moduleMap = {};
    }
};__VITE_IS_MODERN__&&p("http:/auth.mlplat.com/static/");